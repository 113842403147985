import request from '@/api/request'
/* 分页查询 */
export function reqApply (params) { return request({ url: '/application/page', params }) }

/* 应用新增 */
export function reqApplyAdd (params) { return request({ url: '/application', method: 'post', params }) }

/* 应用修改 */
export function reqApplyUpdate (params) { return request({ url: '/application', method: 'put', params }) }

/* 通过主键删除应用 */
export function reqApplyDelById (params) { return request({ url: '/application/:id', method: 'delete', params }) }

/* 通过主键查询应用 */
export function reqApplyInquireById (params) { return request({ url: '/application/:id', params }) }

/**
 * @des 菜单模块
 */
/* 菜单树 */
export function reqMenuTree (params) { return request({ url: '/menu/tree', method: 'get', params }) }
/* 创建 */
export function reqMenuCreate (params) { return request({ url: '/menu', method: 'post', params }) }
/* 编辑 */
export function reqMenuEdit (params) { return request({ url: '/menu', method: 'put', params }) }
/* 详情 */
export function reqMenuDetail (params) { return request({ url: '/menu/:id', method: 'get', params }) }
/* 删除 */
export function reqMenuDelete (params) { return request({ url: '/menu/:appId/:id', method: 'delete', params }) }

/**
 * @des app 应用
 */
/* 分页查询 */
export function reqMApply (params) { return request({ url: '/mobile/application/page', params }) }
/* 应用新增 */
export function reqMApplyAdd (params) { return request({ url: '/mobile/application', method: 'post', params }) }
/* 应用修改 */
export function reqMApplyUpdate (params) { return request({ url: '/mobile/application', method: 'put', params }) }
/* 通过主键删除应用 */
export function reqMApplyDelById (params) { return request({ url: '/mobile/application/:id', method: 'delete', params }) }
/* 通过主键查询应用 */
export function reqMApplyInquireById (params) { return request({ url: '/mobile/application/:id', params }) }

/* 菜单树 */
export function reqMMenuTree (params) { return request({ url: '/mobile/menu/tree', method: 'get', params }) }
/* 创建 */
export function reqMMenuCreate (params) { return request({ url: '/mobile/menu', method: 'post', params }) }
/* 编辑 */
export function reqMMenuEdit (params) { return request({ url: '/mobile/menu', method: 'put', params }) }
/* 详情 */
export function reqMMenuDetail (params) { return request({ url: '/mobile/menu/:id', method: 'get', params }) }
/* 删除 */
export function reqMMenuDelete (params) { return request({ url: '/mobile/menu/:appId/:id', method: 'delete', params }) }
