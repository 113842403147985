<template>
    <div>
        <el-page-header @back="goBack"></el-page-header>

        <div class="menu_main">
            <div class="col_left_menu">
                <el-tree
                    ref="menuTree"
                    style="padding-right:5px"
                    show-checkbox
                    check-strictly
                    node-key="id"
                    :data="treeData"
                    :default-expanded-keys="defaultExpandedKeys"
                    :props="{ children: 'children', label: 'label' }"
                    @check="handleCurrentCheck"
                >
                </el-tree>
            </div>
            <div class="col_right_option">
                <div>
                    <el-radio-group size="small" v-model="operationType" @change="handleOperationChange">
                        <el-radio-button label="create">新增</el-radio-button>
                        <el-radio-button label="edit">编辑</el-radio-button>
                        <el-radio-button label="detail">详情</el-radio-button>
                    </el-radio-group>
                    <el-button type="danger" size="small" class="del_btn" @click="handleDelete">删除</el-button>
                </div>
                <div class="form_content">
                    <el-form ref="formMenu" :model="formData" :rules="rules" label-width="110px">
                        <template v-if="operationType !== 'detail'">
                            <el-form-item label="分类">
                                <el-radio-group size="small" v-model="formData.type" :disabled="itemDisable" @change="handleCreateTypeChange">
                                    <el-radio label="0">菜单</el-radio>
                                    <el-radio label="1">按钮</el-radio>
                                    <el-radio label="2">目录</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </template>
                        <template v-if="formData.type === '1'">
                            <el-form-item label="按钮权限编码" prop="permission"> <el-input v-model="formData.permission" :disabled="itemDisable" size="small"></el-input> </el-form-item>
                            <el-form-item label="按钮权名称" prop="powerNamme"> <el-input v-model="formData.powerNamme" :disabled="itemDisable" size="small"></el-input> </el-form-item>
                        </template>
                        <template v-else>
                            <el-form-item label="菜单名称" prop="name">
                                <el-input v-model="formData.name" :disabled="itemDisable" size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="页面名称" prop="pageComponent"> <el-input v-model="formData.pageComponent" :disabled="itemDisable" size="small"></el-input> </el-form-item>
                            <el-form-item label="是否显示" prop="displayTag">
                                <el-radio-group size="small" v-model="formData.displayTag" :disabled="itemDisable">
                                    <el-radio :label="true">是</el-radio>
                                    <el-radio :label="false">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="外部链接" prop="externalUrl">
                                <el-input type="textarea" rows="3" v-model="formData.externalUrl" :disabled="itemDisable" size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="前端URL" prop="path"> <el-input v-model="formData.path" :disabled="itemDisable" size="small"></el-input> </el-form-item>
                            <el-form-item label="图标" prop="icon">
                                <el-autocomplete
                                    v-model="formData.icon"
                                    :fetch-suggestions="querySearch"
                                    value-key="name"
                                    size="small"
                                    :disabled="itemDisable"
                                    style="width: 100%"
                                    clearable
                                >
                                    <template slot-scope="{ item }">
                                        <i :class="item.name"></i>
                                    </template>
                                </el-autocomplete>
                                    <i :class="formData.icon"></i>
                            </el-form-item>
                            <el-form-item label="排序号" prop="sort"> <el-input v-model="formData.sort" :disabled="itemDisable" size="small"></el-input> </el-form-item>
                        </template>

                    </el-form>
                    <div class="content_center" v-if="operationType !== 'detail'">
                        <el-button size="small" type="primary" @click="handleSave">保存</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    reqMenuTree, reqMenuCreate, reqMenuEdit, reqMenuDetail, reqMenuDelete,
    reqMMenuTree, reqMMenuCreate, reqMMenuEdit, reqMMenuDetail, reqMMenuDelete
} from '@/api/platform/apply'

export default {
    /* 菜单管理 */
    name: 'Menu',

    data () {
        return {
            treeData: [],
            /* 默认展开第一级 */
            defaultExpandedKeys: [],
            formData: {
                name: '',
                externalUrl: '',
                path: '',
                icon: '',
                sort: '',
                powerNamme: '',
                pageComponent: '',
                permission: '',
                /* 新增类型 */
                type: '0',
                /* 是否在应用菜单显示的路由 */
                displayTag: false
            },
            rules: {
                name: [{ required: true, message: '请输入', trigger: 'blur' }],
                permission: [{ required: true, message: '请输入', trigger: 'blur' }],
                powerNamme: [{ required: true, message: '请输入', trigger: 'blur' }],
                pageComponent: [{ required: true, message: '请输入', trigger: 'blur' }],
            },
            operationType: 'create',
            currentSelectId: null,
            /* 当前选中菜单的详情 */
            currentDetail: {},
            suggestions: [{ name: 'el-icon-setting' }, { name: 'el-icon-s-data' }],
            /* 包含 app 和 pc */
            moduleName: ''
        }
    },

    created () {
        this.moduleName = this.$route.query.moduleName || 'pc'
        this.getMenuTree()
    },

    computed: {
        itemDisable () {
            return this.operationType === 'detail'
        }
    },

    methods: {
        goBack () {
            this.$router.go(-1)
        },

        querySearch (queryString, cb) {
            var { suggestions } = this
            var results = queryString ? suggestions.filter(this.createFilter(queryString)) : suggestions
            // 调用 callback 返回建议列表的数据
            cb(results)
        },

        createFilter (queryString) {
            return (restaurant) => {
                return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },

        /* 树选择更改 */
        handleCurrentCheck (data, statusObj) {
            let id = ''
            const len = statusObj.checkedKeys.length
            if (len > 0) {
                this.$refs.menuTree.setCheckedKeys([data.id])
                id = data.id
            } else {
                id = ''
            }
            this.operationType = 'create'
            this.formData.type = '0'
            this.currentSelectId = id
            this.$refs.formMenu.resetFields()

            this.reqMenuDetail()
        },

        /* 新增 编辑 详情 切换 */
        handleOperationChange (val) {
            const { formData } = this
            if (val === 'create') {
                this.$refs.formMenu.resetFields()
                Object.keys(formData).forEach(key => {
                    this.formData[key] = ''
                })
                this.formData.type = '0'
            } else if (val === 'edit' || val === 'detail') {
                const { currentDetail, currentSelectId } = this
                if (!currentSelectId) {
                    this.$message.warning('请选择菜单项')
                    this.operationType = 'create'
                    return
                }
                Object.keys(formData).forEach(key => {
                    this.formData[key] = currentDetail[key] || ''
                })
            }
        },

        /* 新增类型 */
        handleCreateTypeChange (val) {
            this.$refs.formMenu.resetFields()
        },

        /* 判断是否是pc应用管理模块 */
        isPc () {
            return this.moduleName === 'pc'
        },

        /* 菜单树处理 */
        getMenuTree () {
            const method = this.isPc() ? reqMenuTree : reqMMenuTree
            method({
                lazy: false,
                appId: this.$route.params.id
            })
                .then(({ data }) => {
                    this.treeData = data
                    this.defaultExpandedKeys = data.map(v => v.id)
                })
        },

        /* 删除操作 */
        handleDelete () {
            const { currentSelectId } = this
            if (currentSelectId) {
                this.$confirm('删除当前选中？', '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const method = this.isPc() ? reqMenuDelete : reqMMenuDelete
                    method({ appId: this.$route.params.id, id: currentSelectId })
                        .then((result) => {
                            this.$message.success('成功')
                            this.getMenuTree()
                        })
                }).catch(() => {})
            } else {
                this.$message.warning('请选择菜单项')
            }
        },

        /* 保存操作 */
        handleSave () {
            this.$refs.formMenu.validate(valid => {
                if (valid) {
                    const { formData, currentSelectId, operationType, currentDetail } = this
                    const { name, powerNamme, permission, path, externalUrl, icon, sort, type, pageComponent, displayTag } = formData
                    let reqData = {}

                    if (type === '1') {
                        /* 按钮权限 */
                        reqData = { name: powerNamme, permission, type }
                    } else {
                        reqData = { name, path, externalUrl, icon, sort, type, pageComponent, displayTag }
                    }

                    reqData.appId = this.$route.params.id

                    if (operationType === 'edit') {
                        reqData.menuId = currentSelectId
                        const method = this.isPc() ? reqMenuEdit : reqMMenuEdit
                        method({ ...currentDetail, ...reqData })
                            .then((result) => {
                                this.$message.success('成功')
                                this.getMenuTree()
                            })
                    } else {
                        if (currentSelectId) {
                            reqData.parentId = currentSelectId
                        } else {
                            reqData.parentId = -1
                        }
                        const method = this.isPc() ? reqMenuCreate : reqMMenuCreate
                        method(reqData)
                            .then((result) => {
                                this.$message.success('成功')
                                this.getMenuTree()
                            })
                    }
                }
            })
        },

        /* 菜单详情 */
        reqMenuDetail () {
            const { currentSelectId } = this
            if (currentSelectId) {
                const method = this.isPc() ? reqMenuDetail : reqMMenuDetail
                method({ id: currentSelectId })
                    .then(({ data }) => {
                        if (data.type === '1') {
                            data.powerNamme = data.name
                            data.name = ''
                        }
                        this.currentDetail = data
                    })
            } else {
                this.currentDetail = {}
                this.$refs.formMenu.resetFields()
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/variable.scss';
.el-page-header{
    padding: 3px;
}
.menu_main{
    display: flex;
    padding: 10px 5px;
    background-color: $c7;
    border-radius: 3px;
}
.col_left_menu{
    min-width: 120px;
    height: 70vh;
    overflow: scroll;
    padding-right: 6px;
    background-color: #fff;
}
.col_right_option{
    min-height: 400px;
    padding-left: 15px;
    border-left: $c3 solid 1px;
}
.form_content{
    margin: 30px 10px 20px;
    width: 600px;
}
.del_btn{
    margin-left: 5px;
}
</style>
